// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from "@rails/ujs"

import 'jquery'

// require("@rails/ujs").start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

Rails.start()

// Function used to import all files from a directory
// function importAll(r) {
//   r.keys().forEach(r)
// }
import 'packs/hyper'
// import 'bootstrap'

// Importing all JS files from app/javascript/packs/hyper directory - used to house all code for Hyper Theme
// importAll(require.context('packs/hyper', true, /\.js$/))

import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = name => images(name, true)
