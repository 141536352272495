/**
 * Theme: Hyper - Responsive Bootstrap 4 Admin Dashboard
 * Author: Coderthemes
 * Module/App: Main Js
 */

import { Util, Alert } from 'bootstrap'
import $ from 'jquery'

// other imports
import 'metismenu'
import 'select2'
import 'jquery-slimscroll/jquery.slimscroll.min.js'
import moment from 'moment'
import 'bootstrap-daterangepicker/daterangepicker.js'
import 'jquery-mask-plugin/dist/jquery.mask.min'
import 'bootstrap-timepicker/js/bootstrap-timepicker.min'
import 'bootstrap-timepicker/css/bootstrap-timepicker.min.css'
import 'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min'
import 'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css'
import 'bootstrap-maxlength'
import hljs from 'highlight.js'

import LeftSideBar from './leftSidebar'
import Topbar from './topbar'
import RightBar from './rightbar'
import Advanced from './advanced'
import Dragula from './dragula'

// style
// import '../scss/app.scss'
// import '../../stylesheets/app.scss'
// in order to have dark theme, import app-dark.scss instead of app.scss
// import '../scss/app-dark.scss';

// in order to have modern theme, import app-modern.scss instead of app.scss
// import '../scss/app-modern.scss';

// in order to have creative theme, import app-creative.scss instead of app.scss
// import '../scss/app-creative.scss';

// import '../scss/icons.scss'

// Layout and theme manager
const LAYOUT_VERTICAL = 'vertical'

const SIDEBAR_THEME_DEFAULT = 'default'
const SIDEBAR_THEME_LIGHT = 'light'
const SIDEBAR_THEME_DARK = 'dark'

const DEFAULT_CONFIG = {
  layout: LAYOUT_VERTICAL,
  sideBarTheme: SIDEBAR_THEME_DEFAULT,
  isBoxed: false,
  isCondensed: false,
  isScrollable: false,
}

class LayoutTheme {
  constructor() {
    this.body = $('body')
    this.window = $(window)

    this.leftSideBar = new LeftSideBar()
    this.topbar = new Topbar()

    this._config = {}
    this.defaultSelectedStyle = null
  }

  /**
   * Preserves the config
   */
  _saveConfig(newConfig) {
    this._config = { ...this._config, ...newConfig }
    sessionStorage.setItem('_HYPER_WP_CONFIG_', JSON.stringify(this._config))
  }

  /**
   * Retuns the locally saved settings
   */
  _getStoredConfig() {
    var config = sessionStorage.getItem('_HYPER_WP_CONFIG_')
    return config ? JSON.parse(config) : DEFAULT_CONFIG
  }

  /**
   * Apply the given config and sets the layout and theme
   */
  _applyConfig() {
    // getting the saved config if available
    this._config = this._getStoredConfig()

    // init menu
    this.leftSideBar.init()
    this.topbar.init()
  }

  /**
   * Initilizes the layout
   */
  _adjustLayout() {
    // in case of small size, add class enlarge to have minimal menu
    if (this.window.width() >= 767 && this.window.width() <= 1028) {
      this.activateCondensedSidebar(true)
    } else {
      var config = this._getStoredConfig()
      if (!config.isCondensed && !config.isScrollable)
        this.deactivateCondensedSidebar()
    }
  }

  /**
   * Resets the sidebar scroll
   */
  _resetSidebarScroll() {
    // sidebar - scroll container
    $('.slimscroll-menu').slimscroll({
      height: 'auto',
      position: 'right',
      size: '5px',
      color: '#9ea5ab',
      wheelStep: 5,
      touchScrollStep: 20,
    })
  }

  /**
   * Activate fluid mode
   */
  activateFluid() {
    this._saveConfig({ isBoxed: false })
    this.body.attr('data-layout-mode', 'fluid')
  }

  /**
   * Activate boxed mode
   */
  activateBoxed() {
    this._saveConfig({ isBoxed: true })
    this.body.attr('data-layout-mode', 'boxed')
  }

  /**
   * Activates the condensed side bar
   */
  activateCondensedSidebar(ignoreToSave) {
    if (!ignoreToSave) {
      this._saveConfig({
        isCondensed: true,
        isScrollable: false,
      })
    }
    this.leftSideBar.activateCondensedSidebar()
  }

  /**
   * deactivates the condensed side bar
   */
  deactivateCondensedSidebar() {
    this._saveConfig({ isCondensed: false })
    this.leftSideBar.deactivateCondensedSidebar()
  }

  /**
   * Activates the scrollable sidenar
   */
  activateScrollableSidebar() {
    this._saveConfig({ isScrollable: true, isCondensed: false })
    this.leftSideBar.activateScrollableSidebar()
  }

  /**
   * Deactivates the scrollable sidenar
   */
  deactivateScrollableSidebar() {
    this._saveConfig({ isScrollable: false })
    this.leftSideBar.deactivateScrollableSidebar()
  }

  /**
   * Activates the default theme
   */
  activateSidebarDefaultTheme() {
    this.leftSideBar.activateDefaultTheme()
    this._saveConfig({ theme: SIDEBAR_THEME_DEFAULT })
  }

  /**
   * Activates the light theme
   */
  activateSidebarLightTheme() {
    this.leftSideBar.activateLightTheme()
    this._saveConfig({ theme: SIDEBAR_THEME_LIGHT })
  }

  /**
   * Activates the dark theme
   */
  activateSidebarDarkTheme() {
    this.leftSideBar.activateDarkTheme()
    this._saveConfig({ theme: SIDEBAR_THEME_DARK })
  }

  /**
   * Clear out the saved config
   */
  clearSavedConfig() {
    sessionStorage.removeItem('_HYPER_CONFIG_')
    this._config = DEFAULT_CONFIG
  }

  /**
   * Gets the config
   */
  getConfig() {
    return this._config
  }

  /**
   * Reset to default
   */
  reset() {
    this.clearSavedConfig()

    this.deactivateCondensedSidebar()
    this.deactivateScrollableSidebar()
    this.activateSidebarDefaultTheme()
    this.activateFluid()
  }

  /**
   * Init
   */
  init() {
    var self = this

    // initilize the menu
    this._applyConfig()

    // adjust layout based on width
    this._adjustLayout()

    // on window resize, make menu flipped automatically
    this.window.on('resize', function(e) {
      e.preventDefault()
      self._adjustLayout()
      self._resetSidebarScroll()
    })
  }
}

// App
class HyperApp {
  constructor() {
    this._body = $('body')
    this._window = $(window)

    this.wrapperEl = $('#mainwrapper')

    // remove loading
    window.setTimeout(() => {
      window.setTimeout(() => {
        document.body.classList.remove('loading')
      })
    })

    // rightbar
    this.rightBar = new RightBar()

    // layout manager
    this.layoutTheme = new LayoutTheme()

    // advanced forms
    this.advanced = new Advanced()

    // dragula
    this.dr = new Dragula()
  }

  /**
   * Initlizes the plugins
   */
  initPlugins() {
    // Select2
    $('[data-toggle="select2"]').select2()

    // input mask
    $('[data-toggle="input-mask"]').each(function(idx, obj) {
      var maskFormat = $(obj).data('maskFormat')
      var reverse = $(obj).data('reverse')
      if (reverse != null) $(obj).mask(maskFormat, { reverse: reverse })
      else $(obj).mask(maskFormat)
    })

    // Datetime and date range picker
    var defaultOptions = {
      cancelClass: 'btn-light',
      applyButtonClasses: 'btn-success',
    }

    // date pickers
    $('[data-toggle="date-picker"]').each(function(idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data())
      $(obj).daterangepicker(objOptions)
    })

    //date pickers ranges only
    var start = moment().subtract(29, 'days')
    var end = moment()
    var defaultRangeOptions = {
      startDate: start,
      endDate: end,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month'),
        ],
      },
    }

    $('[data-toggle="date-picker-range"]').each(function(idx, obj) {
      var objOptions = $.extend({}, defaultRangeOptions, $(obj).data())
      var target = objOptions['targetDisplay']
      //rendering
      $(obj).daterangepicker(objOptions, function(start, end) {
        if (target)
          $(target).html(
            start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'),
          )
      })
    })

    // time picker
    defaultOptions = {
      showSeconds: true,
      icons: {
        up: 'mdi mdi-chevron-up',
        down: 'mdi mdi-chevron-down',
      },
    }

    // time picker
    $('[data-toggle="timepicker"]').each(function(idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data())
      $(obj).timepicker(objOptions)
    })

    // touchspin
    $('[data-toggle="touchspin"]').each(function(idx, obj) {
      var objOptions = $.extend({}, {}, $(obj).data())
      $(obj).TouchSpin(objOptions)
    })

    // maxlength
    defaultOptions = {
      warningClass: 'badge badge-success',
      limitReachedClass: 'badge badge-danger',
      separator: ' out of ',
      preText: 'You typed ',
      postText: ' chars available.',
      placement: 'bottom',
    }

    // maxlength
    $('[data-toggle="maxlength"]').each(function(idx, obj) {
      var objOptions = $.extend({}, defaultOptions, $(obj).data())
      $(obj).maxlength(objOptions)
    })

    //initializing tooltip
    $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()

    //initializing popover
    $.fn.popover && $('[data-toggle="popover"]').popover()

    //initializing toast
    $.fn.toast && $('[data-toggle="toast"]').toast()

    //initializing Slimscroll
    $.fn.slimScroll &&
      $('.slimscroll').slimScroll({
        height: 'auto',
        position: 'right',
        size: '5px',
        touchScrollStep: 20,
        color: '#9ea5ab',
      })

    //initializing form validation
    $('.needs-validation').on('submit', function(event) {
      $(this).addClass('was-validated')
      if ($(this)[0].checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }
      return true
    })

    // syntax highlighting

    $(document).ready(function() {
      var text = ''
      document.querySelectorAll('pre span.escape').forEach(function(element) {
        if (element.classList.contains('escape')) {
          text = element.innerText
        } else {
          text = element.innerText
        }
        text = text.replace(/^\n/, '').trimRight() // goodbye starting whitespace
        var to_kill = Infinity
        var lines = text.split('\n')
        for (var i = 0; i < lines.length; i++) {
          if (!lines[i].trim()) {
            continue
          }
          to_kill = Math.min(lines[i].search(/\S/), to_kill)
        }
        var out = []
        for (i = 0; i < lines.length; i++) {
          out.push(lines[i].replace(new RegExp('^ {' + to_kill + '}', 'g'), ''))
        }
        element.innerText = out.join('\n')
      })

      document.querySelectorAll('pre span.escape').forEach(function(block) {
        hljs.highlightBlock(block)
      })
    })
  }

  /**
   * Activates the default theme
   */
  activateSidebarDefaultTheme() {
    this.layoutTheme.activateSidebarDefaultTheme()
  }

  /**
   * Activates the light theme
   */
  activateSidebarLightTheme() {
    this.layoutTheme.activateSidebarLightTheme()
  }

  /**
   * Activates the dark theme
   */
  activateSidebarDarkTheme() {
    this.layoutTheme.activateSidebarDarkTheme()
  }

  /**
   * Activates the condensed sidebar
   */
  activateCondensedSidebar() {
    this.layoutTheme.activateCondensedSidebar()
  }

  /**
   * Deactivates the condensed sidebar
   */
  deactivateCondensedSidebar() {
    this.layoutTheme.deactivateCondensedSidebar()
  }

  /**
   * Activates the scrollable sidebar
   */
  activateScrollableSidebar() {
    this.layoutTheme.activateScrollableSidebar()
  }

  /**
   * Deactivates the scrollable
   */
  deactivateScrollableSidebar() {
    this.layoutTheme.deactivateScrollableSidebar()
  }

  /**
   * Activates the boxed mode
   */
  activateBoxed() {
    this.layoutTheme.activateBoxed()
  }

  /**
   * Activate the fluid mode
   */
  activateFluid() {
    this.layoutTheme.activateFluid()
  }

  /**
   * clear the saved layout related settings
   */
  clearSavedConfig() {
    this.layoutTheme.clearSavedConfig()
  }

  /**
   * Gets the layout config
   */
  getLayoutConfig() {
    return this.layoutTheme.getConfig()
  }

  /**
   * Reset the layout
   */
  resetLayout() {
    this.layoutTheme.reset()
  }

  /**
   * Initilizes the various things including layout, sidebar, etc
   */
  init() {
    // initilizes the layout
    this.layoutTheme.init()

    // initilizes the right side bar
    this.rightBar.init()

    // initialize advanced
    this.advanced.init()

    //initialize dragula
    this.dr.init()

    // showing the sidebar on load if user is visiting the page first time only
    if (window.sessionStorage) {
      var alreadyVisited = sessionStorage.getItem('_HYPER_VISITED_')
      if (!alreadyVisited) {
        this.rightBar.toggleRightSideBar()
        sessionStorage.setItem('_HYPER_VISITED_', true)
      }
    }

    // plugins
    this.initPlugins()

    // Preloader
    this._window.on('load', function() {
      $('#status').fadeOut()
      $('#preloader')
        .delay(350)
        .fadeOut('slow')
    })
  }
}

// init app

document.addEventListener('turbolinks:load', () => {
  window.hyperApp = new HyperApp()
  window.hyperApp.init()
})
